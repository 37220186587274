import { Star } from '@mui/icons-material';

import styles from './StarRatings.module.scss';

interface IStarRatingsProps {
  stars: number;
}

const starsLength = 5;

export const StarRatings = ({ stars }: IStarRatingsProps) => {
  return (
    <div className={styles.stars}>
      {[...Array(starsLength)].map((_, i) => {
        if (stars > i) {
          return <Star className={styles.active} key={i} />;
        }

        return <Star key={i} />;
      })}
    </div>
  );
};
