import type { SVGProps } from 'react';

export const ArrowRight = ({
  className,
  fill,
  stroke,
  strokeWidth,
}: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      fill={fill || 'none'}
      height="30"
      viewBox="0 0 22 30"
      width="22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_9660_37628)">
        <line
          stroke={stroke ?? '#A2A9AD'}
          strokeLinecap="round"
          strokeWidth={strokeWidth ?? '1.5'}
          x1="2.25"
          x2="20.75"
          y1="15.4761"
          y2="15.4761"
        />
        <line
          stroke={stroke ?? '#A2A9AD'}
          strokeLinecap="round"
          strokeWidth={strokeWidth ?? '1.5'}
          transform="matrix(-0.794358 -0.60745 0.794358 -0.60745 21.5 15.0496)"
          x1="0.75"
          x2="6.99694"
          y1="-0.75"
          y2="-0.75"
        />
        <line
          stroke={stroke ?? '#A2A9AD'}
          strokeLinecap="round"
          strokeWidth={strokeWidth ?? '1.5'}
          transform="matrix(-0.794358 0.60745 0.794358 0.60745 21.5 15.6379)"
          x1="0.75"
          x2="6.99694"
          y1="-0.75"
          y2="-0.75"
        />
      </g>
      <defs>
        <clipPath id="clip0_9660_37628">
          <rect
            fill="white"
            height="30"
            transform="translate(0.5)"
            width="21"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
