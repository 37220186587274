import type { PlayerItemResponsePagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/contexts/SiteProvider';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './LatestCommits.module.scss';
import { LatestPlayer } from './LatestPlayer';

export const LatestCommits = ({ list }: PlayerItemResponsePagedData) => {
  const { currentSite, defaultYears } = useSite();
  const { footballRecruitingYear } = defaultYears;
  const recruitingYear = footballRecruitingYear;
  const textColor = !currentSite?.isNational ? 'primary' : 'inherit';

  return (
    <SidebarWrapper className={styles.sidebarContainer}>
      <header className={styles.commitsHeader}>
        <h3 color={textColor}>Latest Commitments</h3>
        {!currentSite?.isNational && (
          <TextLink
            className={styles.viewAll}
            href={`/college/${currentSite?.slug}/football/${recruitingYear}/industry-comparison-commits/`}
            underline="none"
            variant="subtitle1"
          >
            View all
          </TextLink>
        )}
      </header>
      <ul className={styles.latestCommitsList}>
        {list?.slice(0, 5).map((player) => {
          return <LatestPlayer data={player} key={player.player?.key} />;
        })}
      </ul>
      {currentSite?.isNational && (
        <footer className={styles.footer}>
          <TextLink
            className={styles.viewAll}
            href={`/recruiting/latest-commits/?sport=football`}
            underline="none"
            variant="subtitle1"
          >
            Football
          </TextLink>
          <TextLink
            className={styles.viewAll}
            href={`/recruiting/latest-commits/?sport=basketball`}
            underline="none"
            variant="subtitle1"
          >
            Basketball
          </TextLink>
        </footer>
      )}
    </SidebarWrapper>
  );
};
