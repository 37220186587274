import clsx from 'clsx';
import { Text } from 'components/UI/Typography/Text';

import styles from './SideModuleWrapper.module.scss';

interface ISideModuleWrapperProps {
  className?: string;
  children: React.ReactNode;
  title: string;
}

export const SideModuleWrapper = ({
  className,
  children,
  title,
}: ISideModuleWrapperProps) => {
  return (
    <section className={className}>
      <header>
        <Text className={styles.title} component="h2">
          {title}
        </Text>
      </header>
      {children}
    </section>
  );
};
