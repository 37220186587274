import { Button } from '@mui/material';
import { useSite } from '@on3/ui-lib/contexts/SiteProvider';
import { externalApi } from '@on3/ui-lib/src/utils/api';
import clsx from 'clsx';
import { TextInput } from 'components/UI/TextInput/TextInput';
import { Text } from 'components/UI/Typography/Text';
import { useCallback, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import styles from './SignUp.module.scss';

type FormValues = {
  email: string;
};

const required = { required: true };

interface ISignUpProps {
  titleText?: string;
  bodyText?: string;
  buttonText?: string;
  whiteBackground?: boolean;
}
export const SignUp = ({
  titleText,
  bodyText,
  buttonText,
  whiteBackground,
}: ISignUpProps) => {
  const { currentSite } = useSite();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const { handleSubmit, control } = useForm<FormValues>();
  const hasNewsletter = currentSite?.newsletterListId;
  const subscribeKey = hasNewsletter ? currentSite?.key : 44;
  const onSubmit: SubmitHandler<FormValues> = ({ email }) => {
    // fire subscribe api.
    externalApi
      .post(`users/v1/sites/${subscribeKey}/subscribe-to-newsletter/`, {
        email,
      })
      .then((response) => {
        // on success, replace input with success message.
        // if already subbed, inform user.
        // if (response.status === 200 && response?.data?.message) {
        if (response?.data?.message) {
          setMessage(response?.data?.message);
          setShowMessage(true);
        } else {
          setMessage('Failed to subscribe. Please try again later');
          setShowMessage(true);
        }
      });
  };

  const RenderedEmailInput = useCallback(
    // @ts-expect-error react-hook-form typing
    ({ field }) => (
      <TextInput
        className={styles.input}
        color="primary"
        label="Email..."
        size="small"
        type="email"
        {...field}
      />
    ),
    [],
  );

  return (
    <div
      className={clsx(styles.footerContainer, {
        [styles.whiteBackground]: whiteBackground,
      })}
    >
      <section className={styles.footerWrapper}>
        <header className={styles.header}>
          <Text className={styles.title} variant="h2">
            {titleText ?? 'Newsletter'}
          </Text>
          <Text variant="body1">
            {bodyText ??
              'The No. 1 College Sports newsletter delivering breaking news and the most popular reads straight to your inbox.'}
          </Text>
        </header>
        <div className={styles.messageWrapper}>
          {showMessage ? (
            <Text className={styles.message} variant="h2">
              {message}
            </Text>
          ) : (
            <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
              {/* This needs some MUI Styling work (input border radius). and label needs color primary */}
              <Controller
                control={control}
                name="email"
                render={RenderedEmailInput}
                rules={required}
              />

              <Button
                className={styles.submit}
                color="primary"
                type="submit"
                variant="contained"
              >
                {buttonText ?? "I'm game."}
              </Button>
            </form>
          )}
        </div>
      </section>
    </div>
  );
};
