import type { HeadlineResponsePagedData } from '@on3/api';
import clsx from 'clsx';
import { type ReactNode, useMemo } from 'react';

import { ArticleCover } from './ArticleCover';
import styles from './CoverList.module.scss';

interface IArticleModeListProps extends HeadlineResponsePagedData {
  children?: ReactNode;
  backgroundColor?: string;
  categoryColor?: string;
  titleColor?: string;
  authorColor?: string;
  isHome?: boolean; // Temporary prop to determine if the CoverList is on the home page
}

export const CoverList = ({
  children,
  backgroundColor = '#F5F6F9',
  categoryColor = '#FE3B1F',
  titleColor = '#202733',
  authorColor = '#6f747b',
  list = [],
  isHome = false,
}: IArticleModeListProps) => {
  const backgroundColorStyle = useMemo(() => {
    return { backgroundColor };
  }, [backgroundColor]);

  return (
    <div
      className={clsx(styles.topcontainer, {
        [styles.home]: isHome,
      })}
      style={backgroundColorStyle}
    >
      {children}

      <section className={styles.block}>
        <div className={styles.container}>
          {list?.map((item, index) => {
            return (
              <div className={styles.box} key={index}>
                <ArticleCover
                  authorColor={authorColor}
                  categoryColor={categoryColor}
                  index={index}
                  item={item}
                  titleColor={titleColor}
                />
              </div>
            );
          })}
        </div>
      </section>
    </div>
  );
};
