import type { NilHundredResponseNil100FilterResponseRelatedPagedData } from '@on3/api';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { Nil100 } from 'components/Svg/Nil100';
import { SidebarWrapper } from 'components/UI/Layout/Containers/Sidebar/SidebarWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import { Nil100WidgetItems } from './Nil100WidgetItems';
import styles from './Nil100WidgetItems.module.scss';

const borderStyle = (borderColor?: string | null) => ({
  borderTop: `2px solid${borderColor || '#fd3b1f'}`,
});

interface INil100Widget
  extends NilHundredResponseNil100FilterResponseRelatedPagedData {
  /**Option to force a Team view */
  isTeam?: boolean;
  gender?: 'mens' | 'womens';
}
export const Nil100Widget = ({ list, isTeam, gender }: INil100Widget) => {
  const { currentSite } = useSite();
  const primaryColor = currentSite?.organization?.primaryColor ?? '#fe3b1f';

  const viewAllLink =
    gender === 'womens'
      ? '/nil/rankings/player/womens-nil-100/'
      : '/nil/rankings/player/nil-100/';

  return (
    <SidebarWrapper className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <span className={styles.nil100Icon}>
          <Nil100 fill={primaryColor} />
        </span>
      </div>

      <div
        className={styles.subTitleContainer}
        style={borderStyle(primaryColor)}
      >
        <Text className={styles.subTitle} variant="subtitle1">
          NIL 100 Rank
        </Text>
        <Text className={styles.subTitle} variant="subtitle1">
          NIL Valuation
        </Text>
      </div>
      <ol className={styles.sideContainer}>
        {list?.map((item, index) => {
          return (
            item.person && (
              <Nil100WidgetItems
                {...item}
                gender="womens"
                indexRank={index + 1}
                key={item.person?.key}
              />
            )
          );
        })}
      </ol>
      <footer className={styles.linkContainer}>
        <TextLink
          className={styles.footerCompleteLink}
          color="primary"
          href={viewAllLink}
          underline="none"
          variant="subtitle1"
        >
          Complete List
        </TextLink>
        <TextLink
          className={styles.footerAboutLink}
          color="primary"
          href="/nil/news/about-on3-nil-valuation-per-post-value/"
          underline="none"
          variant="subtitle1"
        >
          About
        </TextLink>
      </footer>
    </SidebarWrapper>
  );
};
