import { TextField, TextFieldProps } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';

import styles from './TextInput.module.scss';

export const TextInput = forwardRef<HTMLDivElement, TextFieldProps>(
  ({ className, error, ...textFieldProps }, ref) => {
    return (
      <TextField
        className={clsx(styles.textInput, className)}
        error={error}
        fullWidth
        ref={ref}
        variant="outlined"
        {...textFieldProps}
      />
    );
  },
);

TextInput.displayName = 'TextInput';
