import type { NilHundredResponse } from '@on3/api';
import {
  formatCurrency,
  formatMillions,
} from '@on3/ui-lib/src/utils/formatters';
import { SportIcon } from 'components/Icons/SportIcon';
import { On3Avatar } from 'components/Image/On3Avatar';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import {
  divisionAbbreviation,
  getAbbreviatedRank,
  getNILProfileLink,
} from './helpers';
import styles from './Nil100WidgetItems.module.scss';

interface Nil100WidgetItemsProps extends NilHundredResponse {
  indexRank: number;
  gender?: 'mens' | 'womens';
}

export const Nil100WidgetItems = ({
  person,
  valuation,
  indexRank,
  gender,
}: Nil100WidgetItemsProps) => {
  if (!person) {
    return null;
  }

  const {
    commitStatus,
    defaultAsset,
    division,
    name,
    defaultSport,
    positionAbbreviation,
  } = person;

  const profileLink = getNILProfileLink({
    sport: person?.defaultSport?.name,
    slug: person?.slug,
  });

  const rank = gender === 'womens' ? indexRank : valuation?.rank;

  return (
    <li className={styles.itemContainer}>
      <Text className={styles.playerRank} variant="subtitle1">
        {rank}
      </Text>

      <On3Avatar
        altText={defaultAsset?.title ?? 'player headshot'}
        className={styles.headShot}
        domain={defaultAsset?.domain}
        height={50}
        source={defaultAsset?.source}
        title={defaultAsset?.title ?? ''}
        width={50}
      />
      <div className={styles.infoContainer}>
        <Text className={styles.positionContainer} variant="caption">
          <span>{divisionAbbreviation(division ?? 'NA')}</span>
          <SportIcon
            className={styles.sportIcon}
            sportName={defaultSport?.name}
          />
          <span>{getAbbreviatedRank(commitStatus?.classRank ?? '')}</span>
        </Text>

        <TextLink
          className={styles.nameLink}
          href={profileLink}
          underline="none"
        >
          {name}
        </TextLink>
        <Text className={styles.positionAbbreviation} variant="caption">
          {positionAbbreviation}
        </Text>
      </div>

      <div className={styles.valuationContainer}>
        <Text className={styles.valuation}>
          {valuation?.valuation ? formatCurrency(valuation?.valuation) : '-'}
        </Text>
        <Text className={styles.followers} variant="caption">
          <span>
            {valuation?.followers ? formatMillions(valuation?.followers) : '-'}
          </span>
          <span>followers</span>
        </Text>
      </div>
    </li>
  );
};
