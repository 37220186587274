import { SVGProps } from 'react';

const SvgAthleteVerified = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={19}
    fill="none"
    viewBox="0 0 16 19"
  >
    <path
      fill="#29F085"
      d="M.005 3.63 7.475.5l7.47 3.13c.029.129.044.116.05.09.006-.062.007-.025 0 0-.012.154-.05.937-.05 4.133 0 5.884-4.928 9.857-7.47 11.109-6.22-2.88-7.571-8.605-7.47-11.109V3.63Z"
    />
    <path
      fill="#000"
      d="M4.322 12.25v-7h5.61v.933H5.396v2.045h4.536v.933H5.396v2.156h4.536v.934h-5.61Z"
    />
  </svg>
);

export default SvgAthleteVerified;
