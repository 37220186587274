export const divisionAbbreviation = (division?: string) => {
  if (division?.includes('Pro')) {
    return 'PRO';
  }

  if (division?.includes('High')) {
    return 'H.S.';
  }

  if (division?.includes('NCAA') || division?.includes('College')) {
    return 'NCAA';
  }

  if (division?.includes('Junior')) {
    return 'JUCO';
  }

  if (division?.includes('Prep')) {
    return 'PREP';
  }
};

const abbreviatedRank = {
  Freshman: 'FR',
  Sophomore: 'SO',
  Junior: 'JR',
  Senior: 'SR',
  'RedShirt Freshman': 'RS-FR',
  'RedShirt Sophomore': 'RS-SO',
  'RedShirt Junior': 'RS-JR',
  'RedShirt Senior': 'RS-SR',
} as const;

export const getAbbreviatedRank = (
  type: keyof typeof abbreviatedRank | string,
) => abbreviatedRank[type as keyof typeof abbreviatedRank] || '';

interface ProfileLinkProps {
  sport?: string | null;
  slug?: string | null;
}
export const getNILProfileLink = ({ sport, slug }: ProfileLinkProps) => {
  if (!slug) {
    return '';
  }

  const isFootballAthlete = sport === 'Football';
  const isBasketballAthlete = sport === 'Basketball';

  return isFootballAthlete || isBasketballAthlete
    ? `/db/${slug}/nil/`
    : `/db/${slug}/`;
};
