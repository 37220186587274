import styles from './HomeLayout.module.scss';

interface ComponentProps {
  children: React.ReactNode;
}

export const HomeLayout = ({ children }: ComponentProps) => {
  return (
    <main className={styles.layout}>
      <div className={styles.container}>{children}</div>
    </main>
  );
};
