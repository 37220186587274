import type { HeadlineResponsePagedData } from '@on3/api';
import { forwardSlashCheck } from '@on3/ui-lib/src/utils/forwardSlashCheck';
import clsx from 'clsx';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';
import { useRouter } from 'next/router';

import { ArticleFeed } from './ArticleFeed';
import styles from './FeedList.module.scss';

interface IViewAllLinkProps {
  url: string;
  text: string;
  title: string;
}

interface IArticleFeedListProps extends HeadlineResponsePagedData {
  section?: string;
  viewAllLinks?: IViewAllLinkProps[];
}

export const FeedList = ({
  list = [],
  section,
  viewAllLinks,
}: IArticleFeedListProps) => {
  const router = useRouter();
  const userPathName = router.pathname === '/user/[slug]';

  return (
    <section
      className={clsx({
        [styles.authorUserContainer]: userPathName,
      })}
    >
      {section && (
        <header>
          <Text className={styles.headerText} variant="h6">
            {section}
          </Text>
        </header>
      )}
      {list?.map((item, index) => {
        return <ArticleFeed {...item} key={index} />;
      })}
      <footer
        className={clsx(styles.footer, {
          [styles.authorUserFooter]: userPathName,
        })}
      >
        {viewAllLinks?.map(({ text, title, url }, index) => {
          const formattedUrl = url ? forwardSlashCheck(url) : '/';

          return (
            <TextLink
              className={styles.footerLink}
              href={formattedUrl}
              key={index}
              title={title}
              variant="subtitle1"
            >
              {text}
            </TextLink>
          );
        })}
      </footer>
    </section>
  );
};
